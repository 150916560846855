.menu {
	background-image: linear-gradient(to top, #118524 0%, #1cc137 100%);
	
	&__main {
		display: flex;
		justify-content: space-between;
	}
	
	&__item {
		font-family: "RobotoBold";
	}
	
	&__link {
		color: #ffffff;
		display: block;
		padding: 23px 0;
		text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);
	}
}

@media (max-width: 1040px) {
	.menu__main {
		display: block;
	}
	.menu__item {
		text-align: center;
	}
	.menu__link {
		padding: 10px 0;
	}
}