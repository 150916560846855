.section-2 {
	background: url(../img/corn.png) no-repeat right bottom, url(../img/pattern.jpg) repeat;
	color: $gray-1;
	font-size: 16px;
	line-height: 1.2;
	padding: 75px 0 57px 0;
	
	.title-2 {
		margin-bottom: 30px;
	}
	
	p {
		letter-spacing: -0.48px;
		margin-bottom: 15px;
	}
}

.listImg {
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
	
	&__item {
		width: 310px;
	}
	
	&__img {
		display: block;
	}
}