.sectionContacts {
	background: url(../img/pattern.jpg) repeat;
	color: $gray-1;
	font-size: 16px;
	line-height: 1.2;
	padding: 57px 0 43px 0;
	position: relative;
	
	&::after {
		background-image: linear-gradient(to top, #118524 0%, #1cc137 100%);
		content: '';
		display: block;
		height: 12px;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 2;
	}
	
	.title-2 {
		color: $gray-1;
		margin: 0 0 25px 0;
		text-align: center;
	}
	
	p {
		margin: 0 0 30px 0;
	}
}

.contactsImg {
	border-radius: 10px;
	display: block;
	margin: 0 auto 33px;
}

.contactsList {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin: 0 0 34px 0;
	
	&__item {
		font-family: "RobotoBold";
		letter-spacing: -0.48px;
		
		&--flex {
			align-items: center;
			display: flex;
		}
	}
	
	a {
		color: $gray-1;
	}
}

.social {
	border-radius: 50%;
	margin-left: 9px;
	overflow: hidden;
	
	img {
		display: block;
	}
}

.map {
	border: 3px solid rgba(64, 64, 64, 0.17);
	border-radius: 15px;
	height: 269px;
	overflow: hidden;
}

@media (max-width: 730px) {
	.contactsList {
		display: block;
	}
	.contactsList__item + .contactsList__item {
		margin-top: 10px;
	}
}