.section-1 {
	align-items: center;
	background-image: url(../img/section-1-bg.jpg);
	color: #ffffff;
	display: flex;
	height: 450px;
	justify-content: center;
	text-align: center;
	
	&__center {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.title-1 {
	color: #ffffff;
	letter-spacing: -1.32px;
	margin: 0 0 13px 0;
	text-shadow: 2px 4px 12px rgba(0, 0, 0, 0.54);
}

.str-1 {
	display: block;
	font-size: 18px;
	letter-spacing: -0.54px;
	margin: 0 0 25px 0;
	text-shadow: 2px 4px 12px rgba(0, 0, 0, 0.54);
}