.header {
	padding: 10px 0;
	
	&__center {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
}

.logo {
	img {
		display: block;
	}
}

.contacts {
	text-align: right;
}

.phone {
	color: $black-1;
	display: block;
	font-family: "RobotoBold";
	font-size: 24px;
	letter-spacing: -0.72px;
}

.green {
	color: $green-1;
}

.link-1 {
	color: $black-2;
	letter-spacing: -0.42px;
	line-height: 16px;
}

@media (max-width: 530px) {
	.header {
		padding-bottom: 20px;
		position: relative;
		
		&::after {
			background-image: linear-gradient(to top, #118524 0%, #1cc137 100%);
			bottom: 0;
			content: '';
			display: block;
			height: 12px;
			left: 0;
			position: absolute;
			right: 0;
			width: 100%;
			z-index: 2;
		}
	}
	.header__center {
		display: block;
		position: relative;
	}
	.header__logo {
		display: block;
		margin: 0 auto 20px;
		width: 175px;
	}
	.header__contacts {
		text-align: center;
	}
}