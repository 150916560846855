.products {
	background: #fff;
	box-shadow: 0 0 100px rgba(0, 0, 0, 0.75);
	height: 418px;
	position: relative;
	
	&__center1 {
		height: 100%;
		position: relative;
	}
	
	&__center2 {
		color: #ffffff;
		font-size: 16px;
		height: 100%;
		line-height: 1.2;
		left: 50%;
		padding: 71px 520px 0 0;
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.75);
		width: 100%;
		z-index: 3;
		
		p {
			margin-bottom: 15px;
		}
	}
	
	&::after {
		background-image: linear-gradient(to top, #118524 0%, #1cc137 100%);
		content: '';
		display: block;
		height: 12px;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 2;
	}
	
	&__Bgleft {
		background: url(../img/green-bg.png) no-repeat right top;
		height: 418px;
		left: 0;
		position: absolute;
		top: 0;
		width: 58%;
		z-index: 1;
	}
	
	&__title {
		background: url(../img/arrow-gold.png) no-repeat left center;
		color: #ffffff;
		margin-bottom: 25px;
		padding: 0 0 0 30px;
		text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.75);
	}
	
	&__img {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
	
	&__span {
		background: #ffffff;
		border: 1px solid rgba(0, 0, 0, 0.31);
		color: $black-3;
		display: inline-block;
		letter-spacing: -0.48px;
		padding: 14.5px 24px;
		text-shadow: none;	
	}
	
	&__btn {
		display: inline-flex;
		max-width: 200px;
		width: 200px;
	}
}

@media (max-width: 1530px) {
	.products__center2 {
		padding-right: 550px;
	}
}

@media (max-width: 1255px) {
	.products__Bgleft {
		width: 59%;
	}
}

@media (max-width: 1040px) {
	.products {
		height: auto;
		padding-top: 20px;
	}
	.products__center2 {
		background: url(../img/green-bg.png) no-repeat left top;
		background-size: cover;
		padding: 50px 15px;
		position: static;
		transform: none;
//		width: 800px;
	}
	.products__Bgleft {
		display: none;
	}
	.products__img {
		display: block;
		margin: 0 auto 20px;
		position: static;
		transform: none;
	}
}

@media (max-width: 820px) {
	.products__center2 {
//		width: 700px;
	}
}

@media (max-width: 720px) {
	.products__center2 {
//		width: 600px;
	}
}

@media (max-width: 630px) {
	.products__center2 {
//		width: 500px;
	}
}

@media (max-width: 530px) {
	.products__center2 {
//		width: 400px;
	}
	.products__span, .products__btn {
		display: block;
		margin: 0 auto 5px;
		padding-left: 5px;
		padding-right: 5px;
		text-align: center;
		width: 200px;
	}
}

@media (max-width: 430px) {
	.products__center2 {
//		width: 100%;
	}
}

	