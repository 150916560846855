.footer {
	background: url(../img/pattern.jpg) repeat;
	padding: 15px 0;
	position: relative;
	
	&::after {
		background-image: linear-gradient(to top, #118524 0%, #1cc137 100%);
		content: '';
		display: block;
		height: 12px;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 2;
	}
	
	&__center {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
}

.pinsite {
	background: url(../img/pin-logo.jpg) no-repeat left top;
	color: $black-4;
	display: block;
	font-size: 13px;
	height: 43px;
	line-height: 15px;
	letter-spacing: -0.39px;
	padding: 7px 0 0 50px;
	width: 175px;
	text-align: center;
}

@media (max-width: 630px) {
	.footer__center {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-row-gap: 20px;
	}
	.footer__logo {
		grid-column: 1 / 2;
		grid-row: 1;
	}
	.footer__contacts {
		grid-column: 2 /3;
		grid-row: 1;
	}
	.pinsite {
		grid-column: 1 / -1;
		grid-row: 2;
	}
}

@media (max-width: 530px) {
	.footer__center {
		display: block;
		text-align: center;
	}
	.footer__logo {
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 175px;
	}
	.pinsite {
		margin: 20px auto;
	}
	.footer__contacts {
		text-align: center;
	}
}

