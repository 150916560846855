.sectionOrder {
	background-image: url(../img/sectionOrder-bg.jpg);
	padding: 65px 0 45px 0;
	
	&__title {
		color: #ffffff;
		letter-spacing: -0.96px;
		text-align: center;
		text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.75);
		text-transform: uppercase;
	}
}

.myform {
	margin: 30px auto 0;
	width: 530px;
	
	&__btn {
		max-width: 100%;
	}
	
	.input, .select, .textarea {
		margin-bottom: 11px;
	}
}

.konf {
	color: #ffffff;
	display: block;
	font-size: 12px;
	letter-spacing: -0.36px;
	line-height: 16px;
	margin: 12px auto 0;
	max-width: 250px;
	text-align: center;
	text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.75);
}

@media (max-width: 630px) {
	.myform {
		width: 100%;
	}
}