.article {
	background: url(../img/pattern.jpg) repeat;
	color: $gray-1;
	font-size: 16px;
	line-height: 1.2;
	padding: 50px 0 80px 0;
	
	&__title {
		color: $gray-1;
		margin-bottom: 25px;
	}
	
	&__imgwrap {
		background: #fff;
		border: 3px solid rgba(64,64,64,.17);
		border-radius: 15px;
		margin: 0 30px 30px 0;
		overflow: hidden;
		width: 460px;
	}
	
	&__img {
		display: block;
		margin: 0 auto;
	}
	
	p {
		margin-bottom: 15px;
	}
}

@media (max-width: 1040px) {
	.article__imgwrap {
		float: none;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (max-width: 530px) {
	.article__imgwrap {
		width: 100%;
	}
}