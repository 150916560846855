@charset "UTF-8";

/**
 * Основные стили
 **/

body {
    background: #fff;
	font-size: 14px;
	font-family: "RobotoRegular";
}

input {
	height: 48px;
}

button {
    border: none;
}

h1, h2, h3, h4, h5, h6 {
	font-family: "RobotoBold";
	font-weight: normal;
    line-height: 1.1;
}

h1 {
	font-size: 44px;
    
    @media (max-width: 630px) {
        font-size: 35px;
    }
    @media (max-width: 530px)  {
        font-size: 30px;
    }
	@media (max-width: 430px) {
		font-size: 25px;
	}
}

h2 {
	font-size: 32px;
	text-transform: uppercase;
	
    @media (max-width: 530px) {
        font-size: 35px;
    }
	@media (max-width: 430px) {
		font-size: 25px;
	}
}

h3 {
    font-size: 34px;
	text-transform: uppercase;
	
    @media (max-width: 530px) {
        font-size: 23px;
    }
}

h4 {
    font-size: 25px;
	text-transform: uppercase;
	
    @media (max-width: 530px) {
        font-size: 22px;
    }
}

h5 {
    font-size: 21px;
	text-transform: uppercase;
}

h6 {
    font-size: 16px;
}

section {
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}

strong {
	font-family: "RobotoBold";
}

input, textarea {
	color: $gray-1;
	font-family: "RobotoRegular";
	font-size: 16px;
	letter-spacing: -0.48px;
	text-align: center;
	
	@include placeholder($gray-1);
}

textarea {
	resize: vertical;
}

select {
	appearance: none;
	background: #fff url("../img/select-arrow.png") no-repeat 95% 15px;
	color: $gray-1;
	font-family: "RobotoRegular";
	font-size: 16px;
	height: 48px;
	letter-spacing: -0.48px;
}

.select {
	display: block;
	text-align-last: center;
	width: 100%;
	
	option {
		padding: 0 10px;
		text-align: center;
	}
}

.input {
	display: block;
	width: 100%;
}

.textarea {
	height: 91px;
	padding: 15px;
	width: 100%;
}

a {
	color: $gray-1;
    text-decoration: none;
}

.clearfix {
    display:inline-block;
}

.clearfix {
    zoom:1;
    display:block;
}

.clearfix:after {
    content:".";
    display:block;
    height:0;
    clear:both;
    visibility:hidden
}

.left {
    float: left;
}

.right {
    float: right;
}

.container {
    margin: 0 auto;
	max-width: 2560px;
}

.center {
    margin: 0 auto;
    max-width: 990px;
}

@media (max-width: 1040px) {
	.center {
		width: 800px;
	}
}

@media (max-width: 820px) {
	.center {
		padding-left: 15px;
		padding-right: 15px;
		width: 700px;
	}
}

@media (max-width: 720px) {
	.center {
		width: 600px;
	}
}

@media (max-width: 630px) {
	.center {
		width: 500px;
	}
}

@media (max-width: 530px) {
	.center {
		width: 400px;
	}
}

@media (max-width: 430px) {
	.center {
		width: 100%;
	}
}

.gold-btn {
	align-items: center;
	background-image: linear-gradient(to top, #d5a000 0%, #fef72d 100%);
	color: #5e4700;
	display: flex;
	font-family: "RobotoBold";
	font-size: 16px;
	height: 50px;
	justify-content: center;
	letter-spacing: -0.48px;
	max-width: 336px;
	width: 100%;
	
	&:hover {
		background-image: linear-gradient(to bottom, #d5a000 0%, #fef72d 100%);
	}
}


