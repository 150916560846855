@charset "UTF-8";
/*========================== reset.css ==========================*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, time {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a, input, select, textarea {
  outline: none; }

img, input {
  border: none;
  height: auto;
  max-width: 100%; }

button {
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0; }

input[type=submit], button[type=submit] {
  cursor: pointer; }

* {
  box-sizing: border-box; }

/* font-family: "RobotoRegular"; */
@font-face {
  font-family: "RobotoRegular";
  src: url("../fonts/RobotoRegular/RobotoRegular.eot");
  src: url("../fonts/RobotoRegular/RobotoRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/RobotoRegular.woff") format("woff"), url("../fonts/RobotoRegular/RobotoRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "RobotoBold"; */
@font-face {
  font-family: "RobotoBold";
  src: url("../fonts/RobotoBold/RobotoBold.eot");
  src: url("../fonts/RobotoBold/RobotoBold.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/RobotoBold.woff") format("woff"), url("../fonts/RobotoBold/RobotoBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/**
 * Основные стили
 **/
body {
  background: #fff;
  font-size: 14px;
  font-family: "RobotoRegular"; }

input {
  height: 48px; }

button {
  border: none; }

h1, h2, h3, h4, h5, h6 {
  font-family: "RobotoBold";
  font-weight: normal;
  line-height: 1.1; }

h1 {
  font-size: 44px; }
  @media (max-width: 630px) {
    h1 {
      font-size: 35px; } }
  @media (max-width: 530px) {
    h1 {
      font-size: 30px; } }
  @media (max-width: 430px) {
    h1 {
      font-size: 25px; } }

h2 {
  font-size: 32px;
  text-transform: uppercase; }
  @media (max-width: 530px) {
    h2 {
      font-size: 35px; } }
  @media (max-width: 430px) {
    h2 {
      font-size: 25px; } }

h3 {
  font-size: 34px;
  text-transform: uppercase; }
  @media (max-width: 530px) {
    h3 {
      font-size: 23px; } }

h4 {
  font-size: 25px;
  text-transform: uppercase; }
  @media (max-width: 530px) {
    h4 {
      font-size: 22px; } }

h5 {
  font-size: 21px;
  text-transform: uppercase; }

h6 {
  font-size: 16px; }

section {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover; }

strong {
  font-family: "RobotoBold"; }

input, textarea {
  color: #404040;
  font-family: "RobotoRegular";
  font-size: 16px;
  letter-spacing: -0.48px;
  text-align: center; }
  input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #404040; }
  input::-moz-placeholder, textarea::-moz-placeholder {
    color: #404040;
    opacity: 1; }
  input:-moz-placeholder, textarea:-moz-placeholder {
    color: #404040;
    opacity: 1; }
  input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: #404040; }

textarea {
  resize: vertical; }

select {
  appearance: none;
  background: #fff url("../img/select-arrow.png") no-repeat 95% 15px;
  color: #404040;
  font-family: "RobotoRegular";
  font-size: 16px;
  height: 48px;
  letter-spacing: -0.48px; }

.select {
  display: block;
  text-align-last: center;
  width: 100%; }
  .select option {
    padding: 0 10px;
    text-align: center; }

.input {
  display: block;
  width: 100%; }

.textarea {
  height: 91px;
  padding: 15px;
  width: 100%; }

a {
  color: #404040;
  text-decoration: none; }

.clearfix {
  display: inline-block; }

.clearfix {
  zoom: 1;
  display: block; }

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.left {
  float: left; }

.right {
  float: right; }

.container {
  margin: 0 auto;
  max-width: 2560px; }

.center {
  margin: 0 auto;
  max-width: 990px; }

@media (max-width: 1040px) {
  .center {
    width: 800px; } }

@media (max-width: 820px) {
  .center {
    padding-left: 15px;
    padding-right: 15px;
    width: 700px; } }

@media (max-width: 720px) {
  .center {
    width: 600px; } }

@media (max-width: 630px) {
  .center {
    width: 500px; } }

@media (max-width: 530px) {
  .center {
    width: 400px; } }

@media (max-width: 430px) {
  .center {
    width: 100%; } }

.gold-btn {
  align-items: center;
  background-image: linear-gradient(to top, #d5a000 0%, #fef72d 100%);
  color: #5e4700;
  display: flex;
  font-family: "RobotoBold";
  font-size: 16px;
  height: 50px;
  justify-content: center;
  letter-spacing: -0.48px;
  max-width: 336px;
  width: 100%; }
  .gold-btn:hover {
    background-image: linear-gradient(to bottom, #d5a000 0%, #fef72d 100%); }

.header {
  padding: 10px 0; }
  .header__center {
    align-items: center;
    display: flex;
    justify-content: space-between; }

.logo img {
  display: block; }

.contacts {
  text-align: right; }

.phone {
  color: #414141;
  display: block;
  font-family: "RobotoBold";
  font-size: 24px;
  letter-spacing: -0.72px; }

.green {
  color: #027f01; }

.link-1 {
  color: #3f3f3f;
  letter-spacing: -0.42px;
  line-height: 16px; }

@media (max-width: 530px) {
  .header {
    padding-bottom: 20px;
    position: relative; }
    .header::after {
      background-image: linear-gradient(to top, #118524 0%, #1cc137 100%);
      bottom: 0;
      content: '';
      display: block;
      height: 12px;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
      z-index: 2; }
  .header__center {
    display: block;
    position: relative; }
  .header__logo {
    display: block;
    margin: 0 auto 20px;
    width: 175px; }
  .header__contacts {
    text-align: center; } }

.menu {
  background-image: linear-gradient(to top, #118524 0%, #1cc137 100%); }
  .menu__main {
    display: flex;
    justify-content: space-between; }
  .menu__item {
    font-family: "RobotoBold"; }
  .menu__link {
    color: #ffffff;
    display: block;
    padding: 23px 0;
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25); }

@media (max-width: 1040px) {
  .menu__main {
    display: block; }
  .menu__item {
    text-align: center; }
  .menu__link {
    padding: 10px 0; } }

.section-1 {
  align-items: center;
  background-image: url(../img/section-1-bg.jpg);
  color: #ffffff;
  display: flex;
  height: 450px;
  justify-content: center;
  text-align: center; }
  .section-1__center {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center; }

.title-1 {
  color: #ffffff;
  letter-spacing: -1.32px;
  margin: 0 0 13px 0;
  text-shadow: 2px 4px 12px rgba(0, 0, 0, 0.54); }

.str-1 {
  display: block;
  font-size: 18px;
  letter-spacing: -0.54px;
  margin: 0 0 25px 0;
  text-shadow: 2px 4px 12px rgba(0, 0, 0, 0.54); }

.section-2 {
  background: url(../img/corn.png) no-repeat right bottom, url(../img/pattern.jpg) repeat;
  color: #404040;
  font-size: 16px;
  line-height: 1.2;
  padding: 75px 0 57px 0; }
  .section-2 .title-2 {
    margin-bottom: 30px; }
  .section-2 p {
    letter-spacing: -0.48px;
    margin-bottom: 15px; }

.listImg {
  display: flex;
  justify-content: space-between;
  margin-top: 30px; }
  .listImg__item {
    width: 310px; }
  .listImg__img {
    display: block; }

.products {
  background: #fff;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.75);
  height: 418px;
  position: relative; }
  .products__center1 {
    height: 100%;
    position: relative; }
  .products__center2 {
    color: #ffffff;
    font-size: 16px;
    height: 100%;
    line-height: 1.2;
    left: 50%;
    padding: 71px 520px 0 0;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.75);
    width: 100%;
    z-index: 3; }
    .products__center2 p {
      margin-bottom: 15px; }
  .products::after {
    background-image: linear-gradient(to top, #118524 0%, #1cc137 100%);
    content: '';
    display: block;
    height: 12px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2; }
  .products__Bgleft {
    background: url(../img/green-bg.png) no-repeat right top;
    height: 418px;
    left: 0;
    position: absolute;
    top: 0;
    width: 58%;
    z-index: 1; }
  .products__title {
    background: url(../img/arrow-gold.png) no-repeat left center;
    color: #ffffff;
    margin-bottom: 25px;
    padding: 0 0 0 30px;
    text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.75); }
  .products__img {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
  .products__span {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.31);
    color: #002406;
    display: inline-block;
    letter-spacing: -0.48px;
    padding: 14.5px 24px;
    text-shadow: none; }
  .products__btn {
    display: inline-flex;
    max-width: 200px;
    width: 200px; }

@media (max-width: 1530px) {
  .products__center2 {
    padding-right: 550px; } }

@media (max-width: 1255px) {
  .products__Bgleft {
    width: 59%; } }

@media (max-width: 1040px) {
  .products {
    height: auto;
    padding-top: 20px; }
  .products__center2 {
    background: url(../img/green-bg.png) no-repeat left top;
    background-size: cover;
    padding: 50px 15px;
    position: static;
    transform: none; }
  .products__Bgleft {
    display: none; }
  .products__img {
    display: block;
    margin: 0 auto 20px;
    position: static;
    transform: none; } }

@media (max-width: 530px) {
  .products__span, .products__btn {
    display: block;
    margin: 0 auto 5px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    width: 200px; } }

.sectionContacts {
  background: url(../img/pattern.jpg) repeat;
  color: #404040;
  font-size: 16px;
  line-height: 1.2;
  padding: 57px 0 43px 0;
  position: relative; }
  .sectionContacts::after {
    background-image: linear-gradient(to top, #118524 0%, #1cc137 100%);
    content: '';
    display: block;
    height: 12px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2; }
  .sectionContacts .title-2 {
    color: #404040;
    margin: 0 0 25px 0;
    text-align: center; }
  .sectionContacts p {
    margin: 0 0 30px 0; }

.contactsImg {
  border-radius: 10px;
  display: block;
  margin: 0 auto 33px; }

.contactsList {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 34px 0; }
  .contactsList__item {
    font-family: "RobotoBold";
    letter-spacing: -0.48px; }
    .contactsList__item--flex {
      align-items: center;
      display: flex; }
  .contactsList a {
    color: #404040; }

.social {
  border-radius: 50%;
  margin-left: 9px;
  overflow: hidden; }
  .social img {
    display: block; }

.map {
  border: 3px solid rgba(64, 64, 64, 0.17);
  border-radius: 15px;
  height: 269px;
  overflow: hidden; }

@media (max-width: 730px) {
  .contactsList {
    display: block; }
  .contactsList__item + .contactsList__item {
    margin-top: 10px; } }

.article {
  background: url(../img/pattern.jpg) repeat;
  color: #404040;
  font-size: 16px;
  line-height: 1.2;
  padding: 50px 0 80px 0; }
  .article__title {
    color: #404040;
    margin-bottom: 25px; }
  .article__imgwrap {
    background: #fff;
    border: 3px solid rgba(64, 64, 64, 0.17);
    border-radius: 15px;
    margin: 0 30px 30px 0;
    overflow: hidden;
    width: 460px; }
  .article__img {
    display: block;
    margin: 0 auto; }
  .article p {
    margin-bottom: 15px; }

@media (max-width: 1040px) {
  .article__imgwrap {
    float: none;
    margin-left: auto;
    margin-right: auto; } }

@media (max-width: 530px) {
  .article__imgwrap {
    width: 100%; } }

.sectionOrder {
  background-image: url(../img/sectionOrder-bg.jpg);
  padding: 65px 0 45px 0; }
  .sectionOrder__title {
    color: #ffffff;
    letter-spacing: -0.96px;
    text-align: center;
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.75);
    text-transform: uppercase; }

.myform {
  margin: 30px auto 0;
  width: 530px; }
  .myform__btn {
    max-width: 100%; }
  .myform .input, .myform .select, .myform .textarea {
    margin-bottom: 11px; }

.konf {
  color: #ffffff;
  display: block;
  font-size: 12px;
  letter-spacing: -0.36px;
  line-height: 16px;
  margin: 12px auto 0;
  max-width: 250px;
  text-align: center;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.75); }

@media (max-width: 630px) {
  .myform {
    width: 100%; } }

.footer {
  background: url(../img/pattern.jpg) repeat;
  padding: 15px 0;
  position: relative; }
  .footer::after {
    background-image: linear-gradient(to top, #118524 0%, #1cc137 100%);
    content: '';
    display: block;
    height: 12px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2; }
  .footer__center {
    align-items: center;
    display: flex;
    justify-content: space-between; }

.pinsite {
  background: url(../img/pin-logo.jpg) no-repeat left top;
  color: #2d2d2d;
  display: block;
  font-size: 13px;
  height: 43px;
  line-height: 15px;
  letter-spacing: -0.39px;
  padding: 7px 0 0 50px;
  width: 175px;
  text-align: center; }

@media (max-width: 630px) {
  .footer__center {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px; }
  .footer__logo {
    grid-column: 1 / 2;
    grid-row: 1; }
  .footer__contacts {
    grid-column: 2 /3;
    grid-row: 1; }
  .pinsite {
    grid-column: 1 / -1;
    grid-row: 2; } }

@media (max-width: 530px) {
  .footer__center {
    display: block;
    text-align: center; }
  .footer__logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 175px; }
  .pinsite {
    margin: 20px auto; }
  .footer__contacts {
    text-align: center; } }

.overlay {
  background: rgba(0, 0, 0, 0.5);
  display: none;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 89; }

.popup {
  background: url(../img/green-bg-small.jpg) repeat-x center top;
  display: none;
  left: 50%;
  max-height: 90%;
  padding: 49px 47px 15px 47px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 381px;
  z-index: 90; }
  .popup__close {
    align-items: center;
    background: #d91a15;
    border: 3px solid #ffffff;
    border-radius: 50%;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.75), inset 0 0 21px rgba(0, 0, 0, 0.26);
    cursor: pointer;
    color: #ffffff;
    display: flex;
    font-size: 18px;
    height: 40px;
    justify-content: center;
    left: calc(50% - 20px);
    letter-spacing: -0.54px;
    position: absolute;
    top: -20px;
    text-transform: uppercase;
    width: 40px; }
    .popup__close:hover {
      background-image: linear-gradient(to bottom, #950606 0%, #dc0000 100%); }
  .popup__title {
    color: #ffffff;
    font-size: 22px;
    letter-spacing: -0.66px;
    margin-bottom: 10px;
    text-align: center;
    text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.51);
    text-transform: none; }
  .popup__konf {
    line-height: 14px;
    max-width: 100%; }

.popupForm .input {
  margin-bottom: 7px;
  width: 100%; }

/*========================== mobile_menu ==========================*/
.toggle-mnu {
  height: 24px;
  display: none;
  width: 28px; }

.toggle-mnu span:after, .toggle-mnu span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 9px; }

.toggle-mnu span:after {
  top: 18px; }

.toggle-mnu span {
  position: relative;
  display: block; }

.toggle-mnu span, .toggle-mnu span:after, .toggle-mnu span:before {
  width: 100%;
  height: 2px;
  background-color: #027f01;
  transition: all 0.3s;
  backface-visibility: hidden;
  border-radius: 2px; }

.toggle-mnu.on span {
  background-color: transparent; }

.toggle-mnu.on span:before {
  transform: rotate(45deg) translate(-1px, 0px); }

.toggle-mnu.on span:after {
  transform: rotate(-45deg) translate(6px, -7px); }

@media (max-width: 1040px) {
  .toggle-mnu {
    display: block; }
  .menu {
    align-items: center;
    background: #027f01;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    right: 0;
    top: 0;
    transition: transform .3s ease;
    transform: translateX(100%);
    width: 70%;
    z-index: 90; }
    .menu__item {
      color: #fff;
      font-size: 18px; }
      .menu__item + .menu__item {
        margin-left: 0;
        margin-top: 15px; }
    .menu__link {
      color: #fff; }
  .menu--active {
    transform: translateX(0); } }

@media (max-width: 530px) {
  .toggle-mnu {
    left: 15px;
    position: absolute;
    top: 30px; } }
