.overlay {
	background: rgba(0, 0, 0, .5);
    display: none;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 89;
}

.popup {
	background: url(../img/green-bg-small.jpg) repeat-x center top;
    display: none;
    left: 50%;
    max-height: 90%;
	padding: 49px 47px 15px 47px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
	width: 381px;
    z-index: 90;
	
	&__close {
		align-items: center;
		background: #d91a15;
		border: 3px solid #ffffff;
		border-radius: 50%;
		box-shadow: 0 0 12px rgba(0, 0, 0, 0.75), inset 0 0 21px rgba(0, 0, 0, 0.26);
		cursor: pointer;
		color: #ffffff;
		display: flex;
		font-size: 18px;
		height: 40px;
		justify-content: center;
		left: calc(50% - 20px);
		letter-spacing: -0.54px;
		position: absolute;
		top: -20px;
		text-transform: uppercase;
		width: 40px;
		
		&:hover {
			background-image: linear-gradient(to bottom, #950606 0%, #dc0000 100%);
		}
	}
	
	&__title {
		color: #ffffff;
		font-size: 22px;
		letter-spacing: -0.66px;
		margin-bottom: 10px;
		text-align: center;
		text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.51);
		text-transform: none;
	}
	
	&__konf {
		line-height: 14px;
		max-width: 100%;
	}
}

.popupForm {
	
	.input {
		margin-bottom: 7px;
		width: 100%;
	}
}

