/*========================== mobile_menu ==========================*/

.toggle-mnu {
	height: 24px;
	display: none;
	width: 28px;
}

.toggle-mnu span:after, .toggle-mnu span:before {
	content: "";
	position: absolute;
	left: 0;
	top: 9px;
}

.toggle-mnu span:after {
	top: 18px;
}
.toggle-mnu span {
	position: relative;
	display: block;
}

.toggle-mnu span, .toggle-mnu span:after, .toggle-mnu span:before {
	width: 100%;
	height: 2px;
	background-color: $green-1;
	transition: all 0.3s;
	backface-visibility: hidden;
	border-radius: 2px;
}

.toggle-mnu.on span {
	background-color: transparent;
}

.toggle-mnu.on span:before {
	transform: rotate(45deg) translate(-1px, 0px);
}

.toggle-mnu.on span:after {
	transform: rotate(-45deg) translate(6px, -7px);
}

@media (max-width: 1040px) {
	.toggle-mnu {
		display: block;
	}
	.menu {
		align-items: center;
		background: $green-1;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: fixed;
		right: 0;
		top: 0;
		transition: transform .3s ease;
		transform: translateX(100%);
		width: 70%;
		z-index: 90;
		
		&__item {
			color: #fff;
			font-size: 18px;
			
			+ .menu__item {
				margin-left: 0;
				margin-top: 15px;
			}
		}
		
		&__link {
			color: #fff;
		}
	}

	.menu--active {
		transform: translateX(0);
	}
}

@media (max-width: 530px) {
	.toggle-mnu {
		left: 15px;
		position: absolute;
		top: 30px;
	}
}

